/**
 * @license
 *
 * Font Family: Kola
 * Designed by: Jean-Baptiste Morizot
 * URL: https://www.fontshare.com/fonts/kola
 * © 2021 Indian Type Foundry
 *
 * Font Style:
 * Kola Regular
 *
*/


@font-face {
  font-family: 'Kola-Regular';
  src: url('./Kola-Regular.woff2') format('woff2'),
       url('./Kola-Regular.woff') format('woff'),
       url('./Kola-Regular.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}

