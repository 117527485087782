@import "./fontspfl/kola.css";
@font-face {
    font-family: entete;
    src: url("./fontspfl/Baloo2-VariableFont_wght.ttf");
}
:root{
    --jaune:#f8eb5d;
    --vert : #d7da19;
    --violet : #3d2f5a;
    --cubeSize : 60; /* en vw */
    --scenePerspective: 5000;
    --cubeRotateY: -0;
    --cubeRotateX: 0;
    --toScroll : 1500; /* px */
  }
  @media (prefers-reduced-motion) {
    :root {
      --scale: 0;
    }
  }
  html {
    scroll-behavior: smooth;
    font-family: Kola-Regular;
    
    }
  body {
    margin: 0;
  
  }
  * {box-sizing: border-box;}
  .front, .bottom {
    background-color:  var(--jaune);
    display: flex;
    flex-direction: column;
    padding-left:50px;
    
  }
  .front h1{
    font-size:5rem;
  }
  .bottom h1 {
    font-size:2.5rem;
  }
  .bottom p {
    font-size:1.5rem;
  }
.bottom h1 {
  font-family: entete;
}
.bottom p {
font-family: entete;
}
  .left{
    background-color: var(--vert);
  }
  .myHeader{
    position: sticky;
    width: 100%;
    min-width: 300px;
    min-height: 300px;
    top:5vh;
  
  }
  .logos{
    display: flex;
    flex: 1 1 0;;
    flex-direction: row;
    width:100%;
    justify-content: space-around;
  }
  .logos img {
    object-fit: contain;
    width:15%;
    height:200px;
    
    

  }
  .content{
    position: relative;
    display: flex;
    width:90vw;
    height: 90vh;
    margin:0 5vw;
    margin-top:  calc(var(--toScroll)*2px);;
    padding: 0;

  }
  .modal{
    position: absolute;
    top:0;
    left: -5000px;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: var(--vert);
    overflow: hidden;
  }
  #myCanvas{
    width: 100%;
    margin-bottom: 10vh;
  }
  .footer{
        position: relative;
        margin-top: 5rem;
        background-color: var(--jaune);
        width: 100%;
        height: 500px;
       /* top: calc(var(--toScroll)*2px);*/
    }
  
    .modal-content{
      display: flex;
      height: 100%;
      padding: 25px ;
    }
    .modal-content-left{
      margin-top: -4rem;
      height:100%;
     } 
     .modal-content-right{
      padding: 1rem 1rem 1rem 1.5rem;
    }
    .modal-content-right p{
      font-family: entete;
    }
    .modalCloseBtn{
      font-size:3rem; 
      text-align: right;
      padding: .5rem 1rem;
    }
    .w-100{
      width: 100%;
    }
    .w-50{
      width: 50%;
    }
  #cube-wrapper {
    position: absolute;
    width: calc(var(--cubeSize) *1vw);
    height: calc(var(--cubeSize) *1vw);
    margin-top: auto;
    margin-left: calc( (100vw - (var(--cubeSize)*1vw))/2);
    margin-right: auto; 
    perspective: calc(var(--scenePerspective) * 1px);
    perspective-origin: 50% 50%;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .cube {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(calc(var(--cubeSize)/2 * -1vw)) 
    rotateY(calc(var(--cubeRotateY) * 1deg)) 
    rotateX(calc(var(--cubeRotateX) * 1deg));
  }
  .cube-face{
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: inherit;
    border: 1px solid black;
  }
  .cube div[class^=face-], .cube div[class*=" face-"] {
width: 100%;
height: 100%;
position: absolute;
backface-visibility: inherit;
border: 1px solid black;
}
.cube .face-1 {
transform: rotateY(0deg) 
translateZ(calc(var(--cubeSize)/2*1vw));
}
.cube .face-2 {
transform: rotateY(180deg) 
translateZ(calc(var(--cubeSize)/2*1vw));
background-color: pink;;
}
.cube .face-3 {
transform: rotateY(90deg) 
translateZ(calc(var(--cubeSize)/2*1vw));
background-color: pink;
}
.cube .face-4 {
transform: rotateY(-90deg) 
translateZ(calc(var(--cubeSize)/2*1vw));
}
.cube .face-5 {
transform: rotateX(90deg) 
translateZ(calc(var(--cubeSize)/2*1vw));
background-color: blueviolet;
}
.cube .face-6 {
transform: rotateX(-90deg) 
translateZ(calc(var(--cubeSize)/2*1vw));
}
